import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { SelectButton } from 'primereact/selectbutton';

import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';

import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { Link } from 'react-router-dom';

import DoTextareaField from './Fields/DoTextareaField';
import DoSelectField from './Fields/DoSelectField';
import DoInputField from "./Fields/DoInputField";
import Loader from '../App/Loader';
import fetchMethodRequest from '../../config/service';
import DoRadioButtons from './Fields/DoRadioButtons';
import DoCheckboxField from './Fields/DoCheckboxField';
import apiCalls from '../../config/apiCalls';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import DoAutoCompleteField from './Fields/DoAutoCompleteField';
import DoSignature from './Fields/DoSignature';
import config from '../../config/config';
import FormsFunctions from '../Cruds/CommonFunctions/FormsFunctions';

let FormsFormModal = (props) => {
  let saveButton =true;

  let [isLoading, setIsLoading] = useState(false);
  let [sessionExpiryModal, setSessionExpiryModal] = useState(false);
  let [formType, setFormType] = useState(props.formType);
  let [formValues, setFormValues] = useState({});
  let [editRowDataID, setEditRowDataID] = useState(props.tableRowData._id ? props.tableRowData._id : "");
  const [formResponses, setFormResponses] = useState(props.tableRowData.formResponses ? props.tableRowData.formResponses : []);
  let [schema, setSchema] = useState('');

  const [formOptions, setFormOptions] = useState([]);
  const [selectedForm, setSelectedForm] = useState({});
  const [doctorSignature, setDoctorSignature] = useState('');
  const [patientSignature, setPatientignature] = useState('');
  const [sig, setSig] = useState(0);
  const [autoCompleteField, setAutoCompleteField] = useState(props.tableRowData);
  const [loginRole, setLoginRole] = useState('');

  const [showPatientSignature, setShowPatientSignature] = useState(false);
  const [patientSignatureIndexs, setPatientSignatureIndexs] = useState([0, 1]);
  const [isPatientSignatureSaved, setIsPatientSignatureSaved] = useState(false);//It Tells the Signature is saved
  const [signatureErrorMsg, setSignatureErrorMsg] = useState(false);//Display the Error alert on the screen
  const signatureContent = config.signatureContent;


  useEffect(() => {
    getALLFormsData();
    setLoginRole(JSON.parse(localStorage.getItem('loginCredentials')).role);
    setSchema(yup.object().shape({
      formName : yup.string().required(`Form is a required field`),
      hospitalId: yup.object().required('Practice Name is a required field'), //validation for Hospital ID
      patientId: yup.object().required('Patient Name is a required field'),//Validation for Patient ID
    }))
    if(props.formType=='add'){{
      setValue('status','Initiated')
    }}
  }, []);

  /**
   * 
   * @param {Function} func 
   * @param {Number} delay 
   * @returns 
   */
  const Debounce = (func, delay) => {//To Avoid the over iterating
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  /**
   * 
   * @param {Number} i 
   */
  const onClickSaveButton = async (i) => {//Modify the Template Text Button - Signature
    if (patientSignature) {
      setShowPatientSignature(false);
      setIsPatientSignatureSaved(true);
      let index = -1;
      let content = selectedForm.templateText;

      let text = await content.replaceAll(signatureContent, (str) => {
        index++;
        if (i === index) {
          return `<img class="mb-5" src='${patientSignature}'/>`;
        }
        return str;
      });
      text = await FormsFunctions.getTemplateData(text);
      setSelectedForm({ ...selectedForm, templateText: text });
      document.getElementById("template").innerHTML = text;
      if (!text.includes(signatureContent)) {
        setSignatureErrorMsg(false);
      }
    }
  }

  const debouncedSave = Debounce(onClickSaveButton, 300);//To Avoid the over iterating

  useEffect(() => {//when we click on the Signature Button it calls
    if (patientSignatureIndexs.length == 1 && props.tableRowData?.status =='To be Signed') {
      if (isPatientSignatureSaved) {//When Signature is already saved
        debouncedSave(patientSignatureIndexs[0]);
      } else {//When Signature is not saved
        setShowPatientSignature(true);
      }
    }
  }, [patientSignatureIndexs])

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    setValue,
    formState: { errors },
    control,
    watch
  } = useForm(
    { resolver: yupResolver(schema), }//validation object
  );


  /**
   * 
   * @param {Object} obj 
   * @returns 
   */
  let submit = async (obj) => {//Save the Data into the Server
    
    let validation = (selectedForm && selectedForm.templateText && (!selectedForm.templateText.includes(signatureContent) || props.tableRowData?.status !== 'To be Signed')) || (selectedForm.type && selectedForm?.type !== 'Template')
    if (validation && saveButton) {
      saveButton=false
      obj.formName = selectedForm ? selectedForm._id : null;
      obj.templateData = selectedForm.templateText ? (await FormsFunctions.getTemplateData(selectedForm.templateText)).replaceAll(/ disabled/g, '') : "";
      obj.formResponses = selectedForm.questions ? formResponses.filter(obj => selectedForm.questions.some(o => obj._id == o._id)) : [];
      obj.doctorSignature = selectedForm.doctorSignature ? doctorSignature : "";
      obj.patientSignature = selectedForm.patientSignature ? patientSignature : "";
      let req = false;
      if (selectedForm.questions) {
        let arr = selectedForm.questions.filter((obj) => obj.Required);
        req = arr.every((obj) => formResponses.some((o) => o._id == obj._id));
      }
      else {
        req = true;
      }
      const formFields = props.formFields(); // Assuming this gets the relevant form fields
      for (let key in autoCompleteField) {
        if (autoCompleteField.hasOwnProperty(key) && typeof autoCompleteField[key] === 'object') {
          const formField = formFields.find(field => field.id === key);
          if (formField && formField.searchField && autoCompleteField[key][formField.searchField]) {
            obj[`autoComplete_${key}`] = autoCompleteField[key][formField.searchField];
          }
        }
      }
      if (obj && req && (!selectedForm.patientSignature || (selectedForm.patientSignature && (patientSignature || props.tableRowData?.status !== 'To be Signed')))) {
        saveDataToServer(obj);
      } else {
        return;
      }
      saveButton=true
    } else if (!selectedForm.type) {

    }
    else if (!validation) {
      setShowPatientSignature(true);
      setSignatureErrorMsg(true);
    }

  }

  const getALLFormsData = async () => {
    let templateArr = [];

    const response2 = await fetchMethodRequest("GET", 'templates');
    templateArr = response2.templates;
    setFormOptions(templateArr);

    if (props.formType == 'edit') {
      setValue("hospitalId", props.tableRowData.hospitalId);
      setValue('savedByPatient',props.tableRowData.savedByPatient ? true : false);
      setValue("patientId", props.tableRowData.patientId);
      setValue("doctorSignature", props.tableRowData.doctorSignature);
      setValue("patientSignature", props.tableRowData.patientSignature);
      
      setPatientignature(props.tableRowData.patientSignature);
      setDoctorSignature(props.tableRowData.doctorSignature);
      if (props.tableRowData.formName) {
        onChangeForm(props.tableRowData.formName, templateArr)
      }
      if (props.tableRowData.formResponses) {
        setFormResponses(props.tableRowData.formResponses)
      }
    }
    setTimeout(() => {
      setValue('status',props.tableRowData?.status?props.tableRowData.status:'To be filled');
    }, 100);
  };

  const setSignatureFun = () => {//Render the Signature Button Onclick
    document.querySelectorAll('button[name="signature"]').forEach((button, index) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        // handleButtonClick(index);
        setPatientSignatureIndexs([index]);
      });
    });
  }

  let getAutoComplete = (i, item) => {
    const itemTemplate = (option) => {

      if(item.showField){
        return (
          <div className="autocomplete-item">
            <div>{`${option[item.searchField]} - ${option[item.showField]}`}</div>
          </div>
        );
      }
      return option[item.searchField];
    };

    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoAutoCompleteField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              filterField={item.filterField}
              filterValue={item.filterValue}
              filterType={item.isNotEq}
              multiple={item.isMultiple}
              fieldState={fieldState}
              errors={errors}
              screen={props.type}
              itemTemplate={itemTemplate}
              searchApi={item.searchApi}
              searchField={item.searchField}
              allow={props.allowDuplicates}
              filterFieldType={item.filterFieldType ? item.filterFieldType : null}
              placeholder={item.placeholder}
              label={item.label}
              getValues={getValues}
              setValue={setValue}
              item={item}
              watch={watch}
              formType={props.formType}
              disabled={item.disabled}
              populateValue={item.searchField}
              populateField={item.showField}
            />)}
        />
      </div>
    )
  }

  let getDropdown = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              input={field}
              id={field.id}
              item={item}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
            />)}
        />
      </div>
    )
  }

  let getSignature = (item) => {
    let signature = item.name == "patientSignature" ? patientSignature : doctorSignature;
    let setSignature = item.name == "patientSignature" ? setPatientignature : setDoctorSignature;
    return <div>
      <DoSignature
        item={item}
        value={signature}
        onChange={e => setSignature(e)}
        disabled={item.disabled}
      />
      {(!signature && item.required) && <small className='text-danger '>Signature is required</small>}
    </div>
  }

  let getDefault = (i, item) => {
    let { t } = props;
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoInputField
              input={field}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              type={item.type ? item.type : "text"}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }
  const handleBackButtonClick = () => {
    window.location.href = `/${props.routeTo}`;
  };
  let getButtonToolbar = () => {
    let { t } = props;
    return (
      <div className='d-flex mt-5'>
        <div className='col-12 px-0'>
          <span className='float-end'>

            <Button color='primary' type="button"
              onClick={handleBackButtonClick}
            >
              {t('Back') ? t('Back') : 'Back'}
            </Button>
            <Button color='primary' outline type="submit" className='savebtn'
            // onClick={handleBackButtonClick}
            >
              {formType && formType === 'add' ?
                t('Send') ? t('Send') : 'Send'
                : t('Update') ? t('Update') : 'Update'
              }
            </Button>
          </span>
        </div>
      </div>
    )
  }

  let clearFormFields = async () => {
    if (props.load) {
      props.load({});
    }
    let formData = [...formFields];
    formData.forEach((item) => {
      item.value = '';
      item.invalid = false;
      item.message = ''
    });
    await setFormFields(formData)
    getFormFields();
  }

  // handleAnswerChange function
  const handleAnswerChange = (questionId, questionText, answer) => {
    let newResponses = [...formResponses];
    const index = newResponses.findIndex(r => r._id === questionId);

    const newResponse = {
      _id: questionId,
      question: questionText,
      answers: answer
    };

    if (index !== -1) {
      if (answer !== "" && answer) {
        newResponses[index] = newResponse;
      } else {
        newResponses.splice(index, 1);
      }

    } else {
      newResponses.push(newResponse);
    }

    setFormResponses(newResponses);
    if (formType == "edit" && sig == 0) {
      setSig(1);
      // setPatientignature(null);
    }
  };

  // renderField function
  const renderField = (question) => {
    const currentResponse = formResponses.find(r => r._id === question._id);
    const options = question.Options?.split(',');

    switch (question.FieldType) {
      case 'CheckBox':
        return (
          <div key={question._id} className="field-container">
            <p className="">{question.Question}</p>
            {options.map(option => (
              <div key={option} className="option-container">
                <Checkbox
                  inputId={option}
                  onChange={e => handleAnswerChange(question._id, question.Question, e.checked ? option : null)}
                  checked={currentResponse?.answers === option}
                  disabled={props.tableRowData?.status=='To be Signed'}
                />
                <label htmlFor={option}>{option}</label>
              </div>
            ))}
            {(!currentResponse && question.Required) && <small className='text-danger '>Above field is required</small>}
          </div>
        );

      case 'Radio':
        return (
          <div key={question._id} className="field-container">
            <p className="">{question.Question}</p>
            {options.map(option => (
              <div key={option} className="option-container">
                <RadioButton
                  inputId={option}
                  name={question._id}
                  value={option}
                  onChange={e => handleAnswerChange(question._id, question.Question, e.value)}
                  checked={currentResponse?.answers === option}
                  disabled={props.tableRowData?.status=='To be Signed'}
                />
                <label htmlFor={option}>{option}</label>
              </div>
            ))}
            {(!currentResponse && question.Required) && <small className='text-danger '>Above field is required</small>}
          </div>
        );

      case 'Dropdown':
        return (
          <div key={question._id} className="field-container">
            <p className="">{question.Question}</p>
            <Dropdown
              value={currentResponse?.answers}
              options={options}
              onChange={e => handleAnswerChange(question._id, question.Question, e.value)}
              disabled={props.tableRowData?.status=='To be Signed'}
            />
            <br></br>
            {(!currentResponse && question.Required) && <small className='text-danger '>Above field is required</small>}
          </div>
        );

      case 'Input':
        return (
          <div key={question._id} className="field-container">
            <p className="">{question.Question}</p>
            <InputText
              value={currentResponse?.answers}
              onChange={e => handleAnswerChange(question._id, question.Question, e.target.value)}
              disabled={props.tableRowData?.status=='To be Signed'}
            />
            <br></br>
            {(!currentResponse && question.Required) && <small className='text-danger '>Above field is required</small>}
          </div>
        );

      case 'TextArea':
        return (
          <div key={question._id} className="field-container">
            <p className="">{question.Question}</p>
            <InputTextarea
              value={currentResponse?.answers}
              onChange={e => handleAnswerChange(question._id, question.Question, e.target.value)}
              disabled={props.tableRowData?.status=='To be Signed'}
            />
            <br></br>
            {(!currentResponse && question.Required) && <small className='text-danger '>Above field is required</small>}
          </div>
        );

      default:
        return null;
    }
  };



  let saveDataToServer = async (userBody) => {
    let validationExists;

    if (!validationExists) {
      if (formValues) {
        let method, apiUrl;

        if (["edit","view"].includes(formType)) {
          delete userBody.email;
          delete userBody.password;
          method = 'PUT';
          apiUrl = `${props.apiUrl}/${editRowDataID}`;
        } else {
          method = 'POST';
          apiUrl = props.apiUrl;
        }
        if (userBody && userBody.patientId){
          let patientSearchField = userBody.patientId?.patientId
          userBody.patientIdSearch =patientSearchField 
        }

        return fetchMethodRequest(method, apiUrl, {...userBody})
          .then(async (response) => {
            let sessionexpired = await localStorage.getItem('sessionexpired');
            if (sessionexpired === "true") {
              setSessionExpiryModal(true);

            }
            if (response && response.respCode) {
              // handleBackButtonClick();//reload
              await props.getDataFromServer(props.filterCriteria);
              showToasterMessage(response.respMessage, 'success');
              if (props.displayViewOfForm === 'modal') {
                props.closeFormModal('save', response.quantityId);
              } else {
                if (formType !== 'add') {
                  window.location.href = `/${props.routeTo}`;
                } else {
                  props.closeFormModal('save', response.quantityId);
                }
              }
              clearFormFields();
              props.reset();
            } else if (response && response.errorMessage) {
              showToasterMessage(response.errorMessage, 'error');
            }
            setIsLoading(false);
          }).catch((err) => {
            return err;
          });
      } else {
        return;
      }
    }
  };

  let closeFormModal = async () => {
    clearFormFields();
    props.getDataFromServer(props.filterCriteria);
    props.closeFormModal();
  }

  let getHeader = () => {
    let { t } = props;

    if (props.displayViewOfForm != "sideForm") {
      const type = props.displayName ? props.displayName : props.type;
      const headerType = props.customHeader ? props.customHeader : (formType === "add" ? `Add ${type}` : formType === "view" ? `View ${type}` : `Edit ${type}`);
      return (
        <div className='d-flex form_form-header'>
          <div className='col-12 px-0 pb-1'>
            <span className='float-left pt-2'>
              <h4 style={{ textTransform: 'capitalize' }}><b>
                <Link to={props.routeTo.startsWith("/") ? props.routeTo : `/${props.routeTo}`} onClick={closeFormModal} className='text-white px-1'>
                  {headerType}
                </Link>
              </b> </h4>
            </span>
          </div>
        </div>
      )
    }
  }

  const getScreenBody = () => {

    return (
      <div className="centered-container">
        {getHeader()}
        {renderQuestionsForm()}
      </div>
    );
  };


  const onChangeForm = async (id, arr) => {
    setValue('formName',id,{setValidation:true});
    let allForms = arr ? arr : formOptions
    let selectedSet = await allForms.find(q => q._id === id);
    if (selectedSet) {
      setValue('status',selectedSet?.filledByFrontDesk ? 'To be filled' : 'Form sent');
      if (props.tableRowData?.status == 'To be Signed') {
        props.tableRowData.templateData = props.tableRowData.templateData.replaceAll(/<input/g, '<input disabled');
      }
      selectedSet = await FormsFunctions.templateTextConverter(selectedSet);
      document.getElementById("template").innerHTML = arr ? props.tableRowData.templateData : selectedSet.templateText ? selectedSet.templateText : "";
      if (arr) {
        selectedSet.templateText = props.tableRowData.templateData
      }
      setSelectedForm(selectedSet);
    }
  };

  let markRequired = () => {
    return (
        <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
        />
    );
  };

  const renderQuestionsForm = () => {
    return (
      <form onSubmit={handleSubmit(submit)} autoComplete={'off'} className="form-container">
        <div className='form-heading'>
          <p classNames="text-left">Select a Form {markRequired()}</p>
          <Dropdown
            value={selectedForm ? selectedForm._id : null}
            options={formOptions.map(obj => {return {name : obj.name, value:obj._id}})}
            onChange={(e) => onChangeForm(e.value)}
            placeholder="Select a Form"
            className="questionsDropdown"
            optionLabel='name'
            filter
            name="form"
            disabled={props.tableRowData?.status=='To be Signed'}
          />
          <p className='text-danger'>{errors['formName']?.message ? errors['formName']?.message:''}</p>
        </div>

        <Loader loader={isLoading} />
        <div className='questions-container'>
          <div className='default-fields'>
            {getAutoComplete(1, { name: 'hospitalId', type: 'text', placeholder : 'Select a Practice Name' , label: 'Practice Name', searchApi: apiCalls.hospitals, searchField: "fullHospitalName", showField: "hospitalID",required:true})}
          </div>
          <div className='default-fields'>
            {getAutoComplete(1, { name: 'patientId',placeholder:'Select a Patient Name', type: 'text', label: 'Patient Name', searchApi: "patients", searchField: "fullName", hasDependency: true, fieldForKey: "hospitalId", hasDependencyField: "hospitalId" ,required:true})}
          </div>
          <div className='default-fields'>
            {getDropdown(1, {
        name: "status",
        type: "dropDown",
        placeholder: "status",
        label: "status",
        header: "status",
        derivedValue: "status=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        options: config.formStatus,
        textAlign: "Center",
        show: true,
        disabled: true,
        field: "status",
        mobile: true,
        globalSearchField: "true",
        required: true,
        displayInSettings: true,
        fieldType: "dropDown",
      },)}
          </div>
          {selectedForm && Array.isArray(selectedForm.questions) &&
            selectedForm.questions.map((item, index) => renderField(item, index))
          }
          <div id="template" className='display_form_data'></div>

          <div className='d-flex justify-content-between'>
            {(selectedForm && selectedForm.doctorSignature) && <div className='default-fields'>
              {getSignature({ name: 'doctorSignature', label: 'Provider Signature', id: 'signature', disabled: loginRole !== config.doctorRole })}
            </div>}
            {(selectedForm && selectedForm.patientSignature && !selectedForm.templateText?.includes(signatureContent) && !selectedForm.templateText?.includes('<img ')) && <div className='default-fields'>
              {getSignature({ name: 'patientSignature', label: 'Patient Signature', id: 'signature',  
              //  disabled: getValues && getValues('status') && ![''].includes('status') ,
              required: props.tableRowData?.status == 'To be Signed' })}
            </div>}
          </div>

          {signatureErrorMsg && <h3 className='text-danger text-center'>Authorize all signatures.</h3>}

          <Dialog className='patient-signature-button-dialog' header="Signature" draggable={false} visible={showPatientSignature && selectedForm.templateText && selectedForm.patientSignature && !isPatientSignatureSaved} onHide={() => { setShowPatientSignature(false); }} >
            {getSignature({ name: 'patientSignature', label: 'Patient Signature', id: 'signature', required: true })}
            <div>
              <Button className='bg-danger m-2' onClick={() => { setShowPatientSignature(false); }}>Cancel</Button>
              <Button className='bg-info m-2' onClick={() => onClickSaveButton(patientSignatureIndexs[0])}>Save</Button>
            </div>
          </Dialog>

          <div>
            {getButtonToolbar()}
          </div>
        </div>
      </form>
    );
  };




  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.value);
  };

  const renderTemplatesForm = () => {
    const dropdownTemplates = templates
      .filter(template => template.name !== 'admin forget password' && template.name !== 'register')
      .map(template => ({
        label: template.name,
        value: template
      }));
    return (
      <div className='templates-dropdown'>
        <Dropdown
          value={selectedTemplate}
          options={dropdownTemplates}
          onChange={handleTemplateChange}
          optionLabel="label"
          placeholder="Select a Template"
        />
        <div className='templates-container'>
          <div
            dangerouslySetInnerHTML={{ __html: selectedTemplate ? selectedTemplate.templateText : '' }}
            className="editor-container">
          </div>
        </div>
      </div>
    );
  }



  let getModalView = () => {
    let { handleSubmit } = props;
    let modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });
    return (
      <Modal
        isOpen={props.openFormModal}
        className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}
      >
        {getModalHeader()}
        {formType === 'view' ?
          <ModalBody className="modal__body mb-0 pt-1">
            <Card className='pb-0 cardForListMargin'>
              <CardBody className='tableCardBody'>
                {getViewBody()}
                {getEditButton()}
              </CardBody>
            </Card>
          </ModalBody> : getModalBody(handleSubmit)}
      </Modal>
    )
  }

  let { displayViewOfForm } = props;
  return (
    <div>
      {setSignatureFun()}
      {getScreenBody()}
    </div>
  );
}


export default withTranslation('common')(FormsFormModal);